
export const ListSongs = [{
  id: 1,
  title: 'Благо есть славить Господа',
  name: 'BlagoEsti'
},
{
  id: 2,
  title: 'Буду жить под защитой',
  name: 'BydyJitiPodZaschitou'
},
{
  id: 3,
  title: 'В нашей жизни самое прекрасное',
  name: 'VHasheiJisniSamoe'
},
{
  id: 4,
  title: 'В Тебе Господь вся жизнь моя',
  name: 'VTebeGospod'
},
{
  id: 5,
  title: 'В Христе одном надежда есть',
  name: 'VChristeOdnom'
},
{
  id: 6,
  title: 'В час, когда труба Господня',
  name: 'VChasKogdaTryba'
},
{
  id: 7,
  title: 'Вдоль по Виа Долоросса',
  name: 'VdoliPoViaDolorossa'
},
{
  id: 8,
  title: 'Велики и чудны',
  name: 'VelikiIChudni'
},
{
  id: 9,
  title: 'Вместе мы с вами верили',
  name: 'VmesteMiSVamiVerili'
},
{
  id: 10,
  title: 'Время дано нам на время',
  name: 'VremyaDanoNaVremya'
},
{
  id: 11,
  title: 'Встает заря',
  name: 'VstaetZarya'
},
{
  id: 12,
  title: 'День за днём и каждое мгновенье',
  name: 'DayZaDay'
},
{
  id: 13,
  title: 'День угас',
  name: 'DayYgas'
},
{
  id: 14,
  title: 'Держит Он меня',
  name: 'DerjitOnMenya'
},
{
  id: 15,
  title: 'Долг мой уплатил',
  name: 'DolgMoyYplatil'
},
{
  id: 16,
  title: 'Есть на небе город',
  name: 'EstiNaNebeGorod'
},
{
  id: 17,
  title: 'Жизнь борьба и в душе и в духе',
  name: 'JiznyBorbaIVDushe'
},
{
  id: 18,
  title: 'Жизнь летит моя',
  name: 'JiznLetitMoya'
},
{
  id: 19,
  title: 'З кожным днем даражэй',
  name: 'ZKojnimDnemDarajei'
},
{
  id: 20,
  title: 'За любовь, за милость, за спасение',
  name: 'ZaLoveZaMilost'
},
{
  id: 21,
  title: 'Звезд дивные алмазы',
  name: 'ZvezdDivnieAlmazi'
},
{
  id: 22,
  title: 'Здесь, на земле, мы только странники',
  name: 'ZdeciNaZemle'
},
{
  id: 23,
  title: 'Земля застыла в ожиданьи',
  name: 'ZemlyaZastilaVOjidanii'
},
{
  id: 24,
  title: 'Ищите прежде Царства',
  name: 'IshitePrejde'
},
{
  id: 25,
  title: 'Как прекрасно всё то, что Твое',
  name: 'KakPrekrasno'
},
{
  id: 26,
  title: 'Как хорошо, что со мною Спаситель',
  name: 'KakHorosho'
},
{
  id: 27,
  title: 'Край чудесный нас ждет',
  name: 'KraiChydeshu'
},
{
  id: 28,
  title: 'Когда Христос меня простил',
  name: 'KogdaHristosMenyaProstil'
},
{
  id: 29,
  title: 'Куда бы я идти хотел',
  name: 'KydaBiYaIdtiHotel'
},
{
  id: 30,
  title: 'Любит Иисус меня',
  name: 'LybitJesusMenya'
},
{
  id: 31,
  title: 'Любить это значит',
  name: 'LybitEtoZnachit'
},
{
  id: 32,
  title: 'Любовь, что не помнит неправды',
  name: 'LoveChtoNePomnit'
},
{
  id: 33,
  title: 'Манит сердце мечтою',
  name: 'ManitSerdceMechtou'
},
{
  id: 34,
  title: 'Милостью, Боже, Твоею живу',
  name: 'MilostiyBoje'
},
{
  id: 35,
  title: 'Мы нужны друг другу',
  name: 'MiNygniDrygDrygy'
},
{
  id: 36,
  title: 'Моя молитва',
  name: 'MoyaMolitva'
},
{
  id: 37,
  title: 'На далеком холме',
  name: 'NaDalekomHolme'
},
{
  id: 38,
  title: 'Не перечесть нам милостей',
  name: 'NeperechestiNam'
},
{
  id: 39,
  title: 'Над церковными куполами',
  name: 'NadCerkovnimiKupolami'
},
{
  id: 40,
  title: 'Научи меня',
  name: 'NaychiMenya'
},
{
  id: 41,
  title: 'Наша жизнь - это Бог',
  name: 'NashaJizniThisGod'
},
{
  id: 42,
  title: 'Наша жизнь - это путь на Небеса',
  name: 'NashaJizniThisPyti'
},
{
  id: 43,
  title: 'Небесный дом оставил',
  name: 'NebesniiDomOstavil'
},
{
  id: 44,
  title: 'О, юность светлая - мечта заветная',
  name: 'OYnostSvetlaia'
},
{
  id: 45,
  title: 'Одна листва',
  name: 'OdnaListva'
},
{
  id: 46,
  title: 'Он шел в жару и зной',
  name: 'OnShelVJary'
},
{
  id: 47,
  title: 'От погибели спасла',
  name: 'OtPogibeliSpasla'
},
{
  id: 48,
  title: 'Поднимаю глаза',
  name: 'PodnimauGlaza'
},
{
  id: 49,
  title: 'Пока огонь любви чудесной',
  name: 'PokaOgoniLybviChydesnoi'
},
{
  id: 50,
  title: 'Просыпаюсь на заре',
  name: 'ProsipausNaZare'
},
{
  id: 51,
  title: 'Пусть угасло небо',
  name: 'PustYgasloNebo'
},
{
  id: 52,
  title: 'Путь, Истина, Жизнь',
  name: 'PutiIstinaJizni'
},
{
  id: 53,
  title: 'Путь к Голгофе далёк',
  name: 'PytKGolgofe'
},
{
  id: 54,
  title: 'С детских лет',
  name: 'SDetskihLet'
},
{
  id: 55,
  title: 'Сколько есть заманчивых дорог',
  name: 'SkolkoEstiZamanchivih'
},
{
  id: 56,
  title: 'Скоро день тот придет',
  name: 'SkoroDayTotPridet'
},
{
  id: 57,
  title: 'Слушайте звуки и песни чудесные',
  name: 'PoklonicCkaruCkarei'
},
{
  id: 58,
  title: 'Сын Божий нам рождён',
  name: 'SonGodNamRojden'
},
{
  id: 59,
  title: 'Там далеко, за облаками',
  name: 'TamDaleko'
},
{
  id: 60,
  title: 'Тихая ночь',
  name: 'TihayaNight'
},
{
  id: 61,
  title: 'Ты искупил мир от греха',
  name: 'TiIskupilMir'
},
{
  id: 62,
  title: 'Ты куда идешь',
  name: 'TiKydaIdesh'
},
{
  id: 63,
  title: 'Ты меня наполни',
  name: 'TiMenyaNapolni'
},
{
  id: 64,
  title: 'Ты пришёл, как дождь',
  name: 'TiPrisheKakDojd'
},
{
  id: 65,
  title: 'Утром, когда встает рассвет',
  name: 'YtromKogdaVstaet'
},
{
  id: 66,
  title: 'Христианин неси огонь',
  name: 'HristianinNesiOgon'
},
{
  id: 67,
  title: 'Чакаем Цябе наш Iсус',
  name: 'ChakaemCyabeNashIsus'
},
{
  id: 68,
  title: 'Что такое христианство?',
  name: 'ChtoTakoeChristiansctvo'
},
{
  id: 69,
  title: 'Юность светлая',
  name: 'UnostSvetlya'
},
{
  id: 70,
  title: 'Я искал Тебя, Боже',
  name: 'YaIskalTebaBoje'
},
{
  id: 71,
  title: 'Я колени склоню',
  name: 'YaKoleniSklonu'
},
{
  id: 72,
  title: 'Я постигаю сквозь слёзы и боль',
  name: 'TiNePechalsya'
},

]